
.modal-open {
  .modal-auth.show,
  .modal-profile.show,
  .modal-reg.show,
  .modal-trader.show {
    & ~ .modal.show {
      @include transition($modal-transition);
      transform: $modal-fade-transform;
      display: none !important;
    }
  }
  .modal-backdrop.show {
    & ~ .modal-backdrop.show {
      display: none !important;
    }
  }
}