/* The side navigation menu */
.sidenav {
  height: 100%; /* 100% Full-height */
  width: 0; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 1050; /* Stay on top */
  top: 0; /* Stay at the top */
  right: 0;
  background-color: #fff; /* Black*/
  overflow-x: hidden; /* Disable horizontal scroll */
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
  display: flex;
  flex-direction: column;

  &-active {
    width: 75vw;
    max-width: 450px;

    @media screen and (max-width: 468px) {
      width: 100%;
      max-width: 100%;
    }
  }

  &-overlay {
    /* Height & width depends on how you want to reveal the overlay (see JS below) */
    height: 100%;
    width: 0;
    position: fixed; /* Stay in place */
    z-index: 1031; /* Sit on top */
    right: 0;
    top: 0;
    background-color: rgb(0, 0, 0); /* Black fallback color */
    background-color: rgba(0, 0, 0, 0.6); /* Black w/opacity */
    overflow-x: hidden; /* Disable horizontal scroll */

    &-active {
      width: 100%;
    }
  }

  &-header {
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    padding-left: 2rem;

    &-close-btn {
      padding: 0;
      font-size: 4rem;
      margin-right: 2rem;
      margin-left: 2rem;
      color: #b0b9b8;
      font-weight: normal;

      &:hover {
        color: #f1f1f1;
        text-decoration: none;
      }
    }
  }

  &-link-container {
    list-style: none;

    li {
      padding-bottom: .5rem;
      text-decoration: none;
      color: #818181;
      display: block;
      transition: 0.3s;
      font-size: 1.5rem;

      &:hover {
        color: #f1f1f1;
      }
    }
  }

  hr {
    margin: 0;
    opacity: .7;
    border: 1px dashed #000;
    color: #fff;
    background-color: #fff;
  }

  &-expandable {
    transition: .5s;
    height: 0;
    overflow: hidden;

    &-active {
      height: auto;
      padding-left: 2.5rem;
      padding-bottom: .8rem;
      overflow: visible;
    }
  }
}
