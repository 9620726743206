
.gradient-overlay {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -2rem;
  z-index: 100;
  &:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 100%;
    height: 115px;
    background-image: linear-gradient(180deg, rgba($white, 0) 0%, $white 70%);
  }
  &.bg-light {
    &:before {
      background-image: linear-gradient(180deg, rgba($light, 0) 0%, $light 70%);
    }
  }

  .btn-lg {
    background-color: #660308;
    border-color: #660308;
    &:hover {
      opacity: .8;
    }
  }
}


.gradient-overlay-2 {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  @include padding-bottom(3.75rem);
  @include padding-top(200px);
  background-image: linear-gradient(180deg, rgba($white, 0) 0%, $white 60%);
}

.dark-overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background:  #000000aa;
}
