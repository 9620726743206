.fv {
  position: fixed;
  z-index: $zindex-fixed;
  right: 20px;
  bottom: 50px;
  //transform: translate(-100%, -100%);
  display: none;


  &.show {
    display: block;
  }
}

.fv-controls {
  position: relative;
  background: $danger;
  color: $white;
  display: flex;
  align-items: center;
  z-index: 1;
  margin-bottom: 3px;
}

.fv-size {
  width: 400px;
  max-width: calc(100vw - 40px);  
  transition: $transition-base;
}

.fv-cbtn {
  display: block;
  padding: 5px;
  line-height: 20px;
  font-size: 14px;
  font-weight: $font-weight-bold;
  color: inherit;

  &-24 {
    padding: 3px;
  }

  .fi {
    display: block;
  }

  &:hover {
    color: inherit;
    text-decoration: none;
    background-color: $primary;
  }

  &.active,
  &[aria-expanded="true"] {
    background-color: $primary;
  }

  &.move {
    cursor: move;
  }
}

.fv-body {
  background: $white;
  box-shadow: 0 0 1.25rem rgba($black, .2);
}

.fv-video {
  background: $light;
  position: relative;
}

.fv-title {
  text-transform: uppercase;
  font-size: 15px;
  font-weight: $font-weight-bold;
  padding-left: 1rem;
}

.fvi-wrap {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.fv-info {
  width: 400px;
  height: auto;
  background: $white;
  z-index: 2;
  position: relative;
 
  .scroll-element.scroll-x {
    display: none !important;
  }
}

.fvi {
  padding: 1rem;
  border-top: $border-width dashed $border-color;
  font-size: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  line-height: $line-height-sm;
  &:first-child {
    border-top: 0;
  }
}
.fvi-time,
.fvi-label {
  font-size: 14px;
}

.fvi-time {
  font-weight: $font-weight-bold;
  letter-spacing: -.14px;
  line-height: 1;
  margin-bottom: .75rem;
  color: $primary;
}
.fvi-label {
  display: inline-block;
  line-height: $line-height-sm;
  color: $white;
  padding: .25rem .5rem;
  background: $primary;
  margin-bottom: .875rem;
}
.fvi-title {
  font-weight: $font-weight-bold;
  letter-spacing: -.16px;
  color: $black;
  line-height: $line-height-sm;
  margin-bottom: .875rem;
}


.fv-help {
  background: $secondary;
  color: $white;
  position: absolute;
  font-weight: $font-weight-bold;
  @include font-size(.875rem);
  z-index: -1;
  bottom: calc(100% + 33px);
  right: 0;
  max-width: 200px;
  padding: .5rem .625rem;
  line-height: 1;
  img {
    display: none;
    position: absolute;
    right: -4px;
    top: 0;
    z-index: 2;
    transform: translateY(-70%);
  }
  &:hover {
    color: $white;
    text-decoration: none;
  }

}

.body-chat {
  .fv-help {
    display: none;
  }
}

@include media-breakpoint-up(lg) {

  .fv-size {
    width: 500px;
    max-width: calc(100vw - 40px - 75px);
  }

  .fv-help {
    width: 75px;
    top: 50%;
    bottom: auto;
    right: auto;
    left: 100%;
    padding: 1rem .625rem;
    img {
      display: block;
    }
  }

  .body-chat {
    .fv.show {
      right: 423px;
    }
  }
}

@include media-breakpoint-up(xl) {

  .fv-size {
    width: 500px;
  }

  .body-chat {
    .fv-size {
      width: 500px;
    }
  }

}

@media (max-height: 768px) and (orientation: landscape) {
  .body-chat {
    .fv.show {
      right: 50%;
    }
    .fv-size {
      max-width: 50vw;
    }
  }
}
@media (max-width: 1020px) {
  .body-chat {
    .fv.show {
      right: 20px;
      bottom: calc(calc(100% + 36px) - 52vw)
    }
  }
}

@media screen and (min-width: 1400px)  {
  .body-chat {
    .fv-size {
      width: 800px;
    }
  }
}