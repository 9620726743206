
#menu {
  &:not(.mm-spn) {
    display: none;
  }
  a {
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    span {
      padding: 0;
      margin-left: 1rem;
    }
  }
}


.mm-spn {
  li {
    &:after {
      margin-left: 0;
    }
    &.mm-fake {
      a {
        padding-bottom: 0;
      }
      &:after {
        border-width: 0;
      }
      i {
        font-style: normal;
      }
    }
  }
}


.mm-spn.mm-spn--light {
  background: $white;
  color: $body-color;
  font-size: 1rem;
}

.mm-spn.mm-spn--navbar:after {
  font-size: 1.25rem;
}

.mm-ocd__content {
  .invisible {
    visibility: visible !important
  }
}


@include media-breakpoint-up(lg) {
  .mm-ocd--open {
    bottom: 100%;
  }

  body {
    &.mm-ocd-opened {
      overflow-y: visible;
      overscroll-behavior: unset;
    }
  }
}
