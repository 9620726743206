
.scroll-wrapper {
  overflow: hidden !important;
  .scroll-content  {
    border: none !important;
    box-sizing: content-box !important;
    height: auto;
    left: 0;
    margin: 0;
    max-height: none;
    max-width: none !important;
    overflow: scroll !important;
    padding: 0;
    position: relative !important;
    top: 0;
    width: auto !important;
    &::-webkit-scrollbar {
      height: 0;
      width: 0;
    }
  }
}

.scroll-element {
  visibility: hidden;
  &.scroll-scrolly_visible,
  &.scroll-scrollx_visible {
    visibility: visible;
  }
}
    
.scrollbar-inner > .scroll-element, 
.scrollbar-inner > .scroll-element div {
  border: none;
  margin: 0;
  padding: 0;
  position: absolute;
  z-index: 10;
}
 
.scrollbar-inner > .scroll-element div  {
  display: block;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
}

 
.scrollbar-inner > .scroll-element.scroll-x  {
  bottom: 2px;
  height: 8px;
  left: 0;
  width: 100%;
  //display: none !important;
}

 
.scrollbar-inner > .scroll-element.scroll-y  {
  height: calc(100% - 10px);
  right: 3px;
  top: 5px;
  width: 4px;
}

 
.scrollbar-inner > .scroll-element .scroll-element_outer  {
  overflow: hidden;
}

 
.scrollbar-inner > .scroll-element .scroll-element_outer,
.scrollbar-inner > .scroll-element .scroll-element_track,
.scrollbar-inner > .scroll-element .scroll-bar {
  border-radius: 4px;
}

 
.scrollbar-inner > .scroll-element .scroll-element_track,
.scrollbar-inner > .scroll-element .scroll-bar  {
  opacity: 0.4;
}

.scrollbar-inner > .scroll-element .scroll-bar  {
  cursor: pointer;
}
 
.scrollbar-inner > .scroll-element .scroll-element_track  {
  background-color: $gray-100;
} 
.scrollbar-inner > .scroll-element .scroll-bar {
  background-color: $primary;
}
.scrollbar-inner > .scroll-element:hover .scroll-bar {
  background-color: $primary;
}
.scrollbar-inner > .scroll-element.scroll-draggable .scroll-bar {
  background-color: $primary;
}
 
 
/* update scrollbar offset if both scrolls are visible */
 
.scrollbar-inner > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_track {
  left: -12px; 
}
.scrollbar-inner > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_track {
  top: -12px;
}
 
 
.scrollbar-inner > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_size {
  left: -12px;
}
.scrollbar-inner > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_size {
  top: -12px;
}