.btn {
  text-transform: uppercase;
  letter-spacing: .88px;
}

.btn-reg {
  @include font-size(1.375rem);
  border-width: $border-width * 3;
  padding-top: .875rem;
  padding-bottom: .875rem;
}

.btn-sm-icon {
  .fi {
    width: 25px;
    height: 25px;
  }
}

.btn-md-icon {
  .fi {
    width: 30px;
    height: 30px;
  }
}

.btn-filter {
  &[aria-expanded="true"] {
    @extend .btn-danger;
  }
}