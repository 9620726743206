
.plan {
  display: flex;
  height: 100%;
  position: relative;
  flex-direction: column;
  cursor: pointer;

  &:before {
    display: block;
    content: "";
    position: absolute;
    left: -.4375rem;
    top: -.4375rem;
    width: calc(100% + .875rem);
    height: calc(100% + .875rem);
    border: 3px solid transparent;
    transition: $transition-base;
  }

  &:hover {
    &:before {
      border-color: rgba($primary, .2);
    }
  }

  &.active {
    &:before {
      border-color: rgba($primary, .7);
    }
  }
}

.plan-body {
  background: $light;
  flex: 1 1 auto;

  > *:last-child {
    margin: 0;
  }
}

.plan-title {
  @include font-size(1.5rem);
  font-weight: $font-weight-bold;
}
.plan-price {
  font-weight: $font-weight-normal;
  @include font-size(1.5rem);

}

.plan-body,
.plan-header {
  @include padding(1.5rem);
}

.plan-header {
  background: rgba($dark, .4);
  color: $white;
  display: flex;
}

.plan-premium {
  .plan-header {
    background: rgba($danger, .7);
  }

  &:hover {
    &:before {
      border-color: rgba($danger, .2);
    }
  }

  &.active {
    &:before {
      border-color: rgba($danger, .7);
    }
  }

}
.plan-pro {
  .plan-header {
    background: $dark;
  }
  &:hover {
    &:before {
      border-color: rgba($dark, .2);
    }
  }

  &.active {
    &:before {
      border-color: $dark;
    }
  }
}
