.detailed {
  &-block {
    &-label {
      background-color: $danger;
      color: $white;
      @include font-size(.875rem);
      @include padding (.625rem 2rem);
      line-height: 1;
      position: absolute;
      left: 0;
      top: calc(2.8125rem + ((4rem - 2.625rem) * 1.5) / 2);
    }
    &-main {
      height: 100%;
      @include padding(2.8125rem 3.875rem 3rem 4.875rem);
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .title {
        @include font-size(2.625rem);
        color: $white;
        font-family: $headings-font-family;
      }
    }
  }

  &-sponsor {
    background: $light;
    text-align: center;
    @include padding(.25rem .75rem);
    border-radius: 18px;
  }

  &-exh {
    .logo {
      // width: 140px;
      // height: 140px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      display: none;
      img {
        max-height: 120px;
      }
    }
    .collapsing {
      
      height: 100px;
      min-height: 100px;
    }
    .collapse:not(.show) {
      height: 100px;
    }
  }

  &-slider {
    background: $white;
    @include padding(1.375rem 2.375rem);

    .slider-control {
      top: calc(50% - 1.375rem);
    }

    .slider-dots {
      margin-top: 1rem;
    }

    &-pe-0 {
      padding-right: 0;
    }

    &-ps-0 {
      padding-left: 0;
    }
  }

  &-slider-no-padding {
    //padding: 0;
    background: none;

    .slider-dots {
      margin-top: .4rem;
    }
  }
}




.collapse-btn {
  cursor: pointer;
  color: $primary;
  &:before{
    content: "mehr ...";
    color: $danger;
  }
  &[aria-expanded="true"]{
    &::before{
      content: "Schließen";
      color: $danger;
    }
  }
}


.bg-kickoff {
  background-color: #40a097;
}


.poster {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
  &-title {
    position: absolute;
    left: 0;
    bottom: 0;
    @include font-size(1.75rem);
    @include padding(.25rem .5rem);
    vertical-align: middle;
    text-transform: uppercase;
    color: $white;
    font-family: $headings-font-family;
    line-height: 1;
    box-shadow: 0 0 14px rgba(0, 0, 0, 0.5);
  }
  &-date {
    padding: .5625rem .875rem;
    background-color: $white;
  }
}