.slideinfo {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;

  &-img {
    border: 3px solid $white;
    background-image: url('../img/slideinfo1.png');
    background-color: $light;
    background-repeat: no-repeat;
    background-position: center bottom 30%;
    background-size: 70%;
  }

  &-body {
    flex: 1 1 auto;
    padding: .75rem 1.25rem 1.125rem;
  }

  &-content {
    margin-bottom: 0.5rem;
  }

}

.img-ratio {
  object-fit: cover;
  width: 100%;
  height: 100%;
}