
.check-list {
  padding: 0;
  margin: 0;
  li {
    font-size: $font-size-base;
    display: block;
    padding-left: 2.1875rem;
    margin-bottom: $font-size-base * $line-height-sm;
    background: no-repeat left top / 1.5rem;
    background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='#{$success}' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'><path d='M22 11.08V12a10 10 0 1 1-5.93-9.14'></path><polyline points='22 4 12 14.01 9 11.01'></polyline></svg>"));
  }
}

.check-list-condensed {
  li {
    margin-bottom: .75rem;
  }
}

.inviteModal {
  .check-list {
    li {
      margin-bottom: .875rem;
    }
  }
  @include media-breakpoint-up(lg) {
    .modal-body {
      background: url('../img/invite-bg.jpg') no-repeat right -100px center / auto 100%;
    }
  }
  @include media-breakpoint-up(xl) {
    .modal-body {
      background-position: right center;
    }
  }
}