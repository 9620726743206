@charset "UTF-8";

@import "common/fonts";

@import "bootstrap/functions";

@import "variables";

@import "bootstrap/bootstrap";

@import "common/main";

@import "common/icons";
@import "common/header";
@import "common/navbar";
@import "common/menu";
@import "common/modal";
@import "common/footer";
@import "common/form";
@import "common/buttons";
@import "common/home";
@import "common/box";
@import "common/overlay";
@import "common/plan";
@import "common/play-btn";
@import "common/slick";
@import "common/tile";
@import "common/avatar";
@import "common/down";
@import "common/scrollbar";
@import "common/rect";
@import "common/bg-img";
@import "common/list";
@import "common/float";
@import "common/slideinfo";
@import "common/detailed";
@import "common/auth";
@import "common/sidenav";
@import "common/klaro";
@import "common/featured";

.fl {
  position: absolute;
  left: 0;
  top: calc(100% + 1px);
  font-size: .75rem;
  padding-left: 14px;
}
.dropdown-filter {
  @include media-breakpoint-up(lg) {
    display: block;
    box-shadow: none;
    position: relative;
    background: transparent;
    padding: 0;
    font-size: 1rem;
  }
}
.home-tab {
  display: none;
  &.show {
    display: block;
  }
}

.side-nav-item {
  display: block;
  background: $white no-repeat right 1rem center / 1.25rem;
  transition: $transition-base;
  border-left: 4px solid transparent;
  margin-bottom: 2px;
  padding: 0.3125rem 1.375rem;
  color: $body-color;
  &.active {
    border-color: $danger;
    color: $danger;
    font-weight: $font-weight-bold;
  }
  &:hover,
  &:focus {
    color: $danger;
    text-decoration: none;
  }
}
.side-nav-dropdown {
  background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='#{$body-color}' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'><polyline points='6 9 12 15 18 9'></polyline></svg>"));
}
.request-supplier-logo {
  max-height: 100px;
}
.collapse:not(.show) {
  display: block;
  height: 0px;
  overflow: hidden;
  @include transition($transition-collapse);
}
.collapse{
  &.show {
    height: auto;
  }
}
.fav-exh-logo {
  max-height: 60px;
  max-width: 150px;
}

.collapse-exh:not(.show) {
  height: 75px;
}

.modal-invite {
  pointer-events: auto;
  width: 100%;
  z-index: 123;
  background: $light url('../img/invite@2x.png') no-repeat right 2.25rem center / 206px;
  @include media-breakpoint-up(xl) {
    margin-top: 1.5rem;
  }

  @media screen and (max-height: 700px) {
    margin-top: 0;
  }
}

.container-invite {
  max-width: 1140px;
}

.invite-success {
  position: absolute;
  left: -1*$modal-header-padding-x;
  top: 0;
  width: calc(100% + #{$modal-header-padding-x});
  height: 100%;
  display: flex;
  align-items: center;
  background: $success;
  color: $white;
  line-height: $line-height-sm;
  padding: $modal-header-padding-x;
  transition: $transition-base;
  transform: translateX(-100%);
  @include media-breakpoint-up(md) {
    left: -1*2.5rem;
    padding: 2.5rem;
    width: calc(100% + 2.5rem);
  }
  &.show {
    transform: translateX(0);
  }
}
.modal-header {
  border-bottom-style: dashed;
}

body {

  .notifyjs-corner {
    z-index: $zindex-modal + 1;
  }

  .notifyjs-bootstrap-base {
    border: 0;
    border-radius: 0;
    padding: 1.25rem;
    padding-left: 60px;
    font-weight: normal;
    background-size: 26px;
    background-position: 1rem 50%;
    white-space: normal;
    max-width: 260px;
  }

  .notifyjs-bootstrap-error {
    background-color: $danger;
    color: $white;
    @include font-size(.875rem);
    line-height: 1.1;
    background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='#{$white}' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'><path d='M22 11.08V12a10 10 0 1 1-5.93-9.14'></path><polyline points='22 4 12 14.01 9 11.01'></polyline></svg>"));


  }

  .notifyjs-bootstrap-success {
    background-color: $success;
    color: $white;
    @include font-size(.875rem);
    line-height: 1.1;
    background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='#{$white}' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'><path d='M22 11.08V12a10 10 0 1 1-5.93-9.14'></path><polyline points='22 4 12 14.01 9 11.01'></polyline></svg>"));
  }
}

.trader-modal {
  &-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 1.25rem;
    margin-bottom: 2rem;
    height: calc(100px + 2rem);
    img {
      max-height: 100px;
    }
  }
  &-title {
    font-weight: $font-weight-bold;
    color: $black;
    margin-bottom: 1.125rem;
    @include font-size(1.0625rem);
  }
}
.trader-list {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  border: $border-width solid $light;
  transition: $transition-base;
  box-shadow: none;
  margin-bottom: .625rem;
  padding: 1px;
  cursor: pointer;
  &:hover {
    border-color: $gray-500;
  }
  &.active {
    border-color: $danger;
    box-shadow: 0 0 1px 1px $danger inset;
  }
  &-logo {
    flex: 0 0 122px;
    height: 83px;
    background: $white;
    padding: .75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-height: calc(83px - 1.5rem);
    }
  }
  &-body {
    flex: 1 0 0%;
    padding: .75rem 1rem;
  }
  &-title {
    color :$black;
    font-weight: $font-weight-bold;
    line-height: 1;
    margin-bottom: .25rem;
  }
  &-copmany {
    @include font-size(.8125rem);
  }
}
@include media-breakpoint-up(lg) {
  .card-columns {
    column-count: 2;
    column-gap: $grid-gutter-width;
    orphans: 1;
    widows: 1;
    > * {
      display: inline-block;
    }
  }
}

.editProfile {
  position: relative;
  padding-right: 2rem;
  color: $gray-700;
  margin-bottom: 1.75rem;
  > a {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
  }
}
#videoModal,
#pdfModal {
  .modal-body {
    position: relative;
  }

  .close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 50;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    background: rgba($white, 0.9);
    color: $primary;
  }
}

.bind-html {
  font-family: $font-family-base !important;
  font-size: $font-size-base;
  * {
    font-family: $font-family-base !important;
    font-size: $font-size-base;
  }
}

.art-exh-logo {
  max-height: 150px;
  max-width: 300px;
}
.mc-wrap {
  height: auto !important;
}

.mc-wrap-scroll {
  max-height: 400px !important;
  height: 400px !important;
}

.input-trigger {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 4rem;
  text-align: center;
  z-index: 1;
  padding: 0.4375rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.req {
  border: $border-width * 2 solid $light;
  @include font-size(.875rem);
  &-title {
    font-weight: $font-weight-bold;
    @include font-size($font-size-base);
  }
  &-body {
    padding: 1.125rem 1.5rem;
  }
  &-thumb {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: .5rem;
    height: 100%;
  }

  @include media-breakpoint-up(lg) {
    &-thumb {
      border-right: $border-width * 2 solid $light;
    }
    &-body {
      padding-left: 0;
    }
  }
}

.option {
  display: block;
  padding: .5rem .75rem;
  background: $light no-repeat right .75rem center / 15px;
  transition: $transition-base;
  color: $dark;

  &:hover {
    background-color: rgba($danger, .2);
    text-decoration: none;
  }

  &.active {
    background-color: rgba($danger, .2);
    background-image: url('../img/icon-check@2x.png');
  }

  &.disabled {
    cursor: not-allowed;
    opacity: .5;
  }
}

.program-switch {
  display: flex;
  border-bottom: 3px solid rgba($dark, .3);
  @include margin-bottom(2.5rem);

  &-item {
    @include font-size(1.5rem);
    font-weight: $font-weight-semibold;
    line-height: 1;
    padding: 0 0 .75rem;
    border-bottom: 3px solid transparent;
    transition: $transition-base;
    margin-right: 1.5rem;
    position: relative;
    transform: translateY(3px);
    color: $dark;
    opacity: .5;
    &:hover {
      text-decoration: none;
      color: $primary;
      border-bottom-color: rgba($primary, .5);
    }
    &.active {
      border-bottom-color: $danger;
      opacity: 1;
    }
  }
}

.filter-reset {
  display: inline-flex;
  @include font-size(0.875rem);
  span {
    display: block;
    line-height: 1;
  }
}
.live-label {
  display: block;
  position: absolute;
  left: -10px;
  background: $danger;
  color: $white;
  @include font-size(.875rem);
  font-weight: $font-weight-semibold;
  line-height: 24px;
  padding: 0 1.5rem;
  &:after,
  &:before {
    content: "";
    display: block;
    position: absolute;
  }
  &:after {
    top: 0;
    left: 100%;
    bottom: 0;
    width: 12px;
    border: 12px solid transparent;
    border-left-color: $danger;
    border-left-width: 7px;
  }
  &:before {
    width: 10px;
    height: 10px;
    left: 0;
    top: 100%;
    border: 5px solid transparent;
    border-right-color: $red-700;
    border-top-color: $red-700;
  }
}

.program-sidebar {
  height: 300px;
  position: relative;
  padding: 3rem 0;

  .live-label {
    top: 1.25rem;
  }

  @include media-breakpoint-up(lg) {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    height: 100%;
    padding: 4rem 0;
  }

  &__inner {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: .875rem 1.5rem;
  }


  .scroll-element.scroll-x {
    display: none !important;
  }

  .program-dates {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    padding: .25rem 1.5rem .875rem;
  }
}


.event {
  @include padding-bottom(1.5rem);
  @include margin-bottom(1.5rem);
  border-bottom: $border-width dashed $primary;
  .btn-arrow {
    padding-top: 0;
    padding-bottom: 0;
    line-height: 34px;
    position: relative;
    margin-right: 15px;
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: -2px;
      left: calc(100% + 2px);
      bottom: -2px;
      width: 19px;
      border: 19px solid transparent;
      border-left-color: inherit;
      border-left-width: 10px;
    }
  }
}

.event-body {
  height: 100%;
  > *:last-child {
    margin-bottom: 0;
  }
}

.event-title {
  font-weight: $font-weight-bold;
  @include font-size(1.125rem);
  margin-bottom: 1rem;
  line-height: $line-height-sm;
}

.event-slogan {
  @include font-size(1.25rem);
  letter-spacing: 1px;
  text-transform: uppercase;
  font-family: $headings-font-family;
}

.program {
  @include padding-bottom(2.5rem);
  @include margin-bottom(2.5rem);
  border-bottom: $border-width dashed $primary;
}

.program-body {
  height: 100%;
  > *:last-child {
    margin-bottom: 0;
  }
  @include media-breakpoint-up(lg) {
    padding-right: 2rem;
    // border-right: $border-width dashed $primary;
  }
}

.program-title {
  font-weight: $font-weight-bold;
  @include font-size(1.125rem);
  margin-bottom: 1.25rem;
  line-height: $line-height-sm;
}

.program-dates {
  //line-height: $line-height-sm;
  color: $dark;
  font-weight: $font-weight-semibold;


  .date {
    @include font-size(1.25rem);
    font-weight: $font-weight-bold;
    color: $danger;
  }

  .time {
    font-style: italic;
  }
}

\:focus {
  outline: 0 !important;
}

.abox {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  border: $border-width * 2 solid $light;
  line-height: $line-height-sm;
  overflow: hidden;
  .ic {
    display: block;
    position: absolute;
    top: .75rem;
    right: .75rem;
    z-index: 2;

    &-view {
      right: calc(.75rem + 35px + .5rem);
    }
  }
  &__header {
    display: flex;
    align-items: center;
    color: $danger;
    @include font-size(.875rem);
    font-weight: $font-weight-bold;
    padding: .25rem calc(.75rem + 70px + .5rem * 2) .25rem 1rem;
    height: 3.5rem;
  }
  &__thumb {
    height: 195px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    img {
      max-height: calc(195px - 1rem * 2);
    }
  }
  &__body {
    flex: 1 1 auto;
    background: $light;
    padding: 1rem;
    font-weight: $font-weight-semibold;
    @include font-size(.9375rem);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 67px;
  }

  &__items {
    border-top: $border-width * 2 solid $light;
    > * {
      border-right: $border-width * 2 solid $light;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: .25rem;
      height: 85px;
      img {
        max-height: calc(85px - .25rem * 2);
      }
      &:first-child {
        border-left: 0;
      }
    }
  }

  &__items2 {
    height: 200px;
    img {
      max-height: calc(200px - .25rem * 2);
    }

    &:first-child {
      border-left: $border-width * 2 solid $light;
      border-top: 0;
      border-bottom: 0;

    }
  }

  &__imgContainer {
    border: $border-width * 2 solid $light;
    display: flex;
    align-items: center;
    background: white;

    @media (max-width: 991px){
     border: 0;
      border-top: $border-width * 2 solid $light;
    }
  }

  &-h {
    background: $white;
    border: 0;
    overflow: visible;
    .abox__body {
      background: $white;
      border-top: $border-width * 2 solid $light;
    }
    .live-label {
      transform: translateY(-50%);
    }
  }
}

.abox-no-border {
  //border: 0;

  .abox__thumb {
    padding: 0 1rem 1rem 1rem;
    img {
      max-height: calc(195px - .1rem * 2);
    }
  }

}

.abox-title {
  margin-bottom: 1.25rem;

  &-lg {
    font-weight: $font-weight-bold;
    text-align: center;
    @include font-size(1.0625rem);
    margin-bottom: 0;
  }
}

.bg-light {
  .abox {
    border-color: $gray-100;
    background: $white;
    &__body {
      background-color: $gray-100;
    }
    &__items {
      border-color: $gray-100;
      > * {
        border-color: $gray-100;
      }
    }
  }
}
.square {
  position: relative;
  display: block;
  width: 100%;
  &:before {
    content: "";
    display: block;
    position: relative;
    padding-top: 100%;
  }
}

.display-5 {
  letter-spacing: 2.2px;
}

.live-landing-video-container {
  display: flex;
  justify-content: center;
  @media (min-width: 992px){
    display: none;
  }
}
.live-landing-video {
  -webkit-box-shadow: 0px 0px 35px 4px rgba(0,0,0,1);
  -moz-box-shadow: 0px 0px 35px 4px rgba(0,0,0,1);
  box-shadow: 0px 0px 35px 4px rgba(0,0,0,1);
  background: black;
  width: 80vw;
  height: 60vw;
  min-width: 400px;
  min-height: 300px;
  margin-bottom: 50px;
}

.live {
  //&-logo {
  //  position: absolute;
  //  bottom: 0;
  //  left: 50%;
  //  transform: translateX(-50%);
  //  padding: 1rem;
  //  background-color: $white;
  //}

  &-header-container {
    position: absolute;
    align-items: flex-end;
    display: flex;
    justify-content: space-around;
    bottom: 10%;
    left: 50%;
    transform: translateX(-50%);

    @media (max-width: 991px){
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      bottom: 0;
    }
  }
  &-logo-container {
  }
  &-logo {
    background-color: $white;
    padding: 1rem;
    max-height: 140px;
    max-width: 20vw;

    @media (max-width: 991px){
      min-width: 300px;
      width: 40vw;
    }
  }
  &-video-container {
    @media (max-width: 991px){
      display: none;
    }
  }
  &-video {
    -webkit-box-shadow: 0px 0px 35px 4px rgba(0,0,0,1);
    -moz-box-shadow: 0px 0px 35px 4px rgba(0,0,0,1);
    box-shadow: 0px 0px 35px 4px rgba(0,0,0,1);
    background: black;
    width: 40vw;
    height: 30vw;
    max-height: 45vh;
    max-width: 72vh;
    margin-left: 10vw;
  }
   &-video-placeholder {
     width: 40vw;
     height: 30vw;
     max-height: 45vh;
     max-width: 72vh;
     margin-left: 10vw;
   }
}

.voting {
  &-logo {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    padding: .1rem;
    background-color: $white;
  }
}

.sub-menu {
  padding: 0 2.375rem;
  background-color: $light;

  span {
    @include font-size(1.125rem);
  }
}

.live-nav-container {
  @media (min-width: 1300px){
    max-width: 1300px;
  }
}

.exhbox {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  border: $border-width * 2 solid $light;
  line-height: $line-height-sm;
  overflow: hidden;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: .25rem 1rem;
    height: 3.5rem;
  }
  &__thumb {
    height: 195px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    img {
      max-height: calc(195px - 1rem * 2);
    }
  }
  &__body {
    flex: 1 1 auto;
    background: $light;
    padding: 1rem;
    font-weight: $font-weight-semibold;
    @include font-size(.9375rem);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 67px;
  }
}

.form-floating-2 {
  .form-control {
    height: 42px;
    width: 200px;
  }
  > label {
    padding: .75rem;
    padding-right: calc(1.5em + 1rem);
  }
}


.exh {
  &__box {
    > * {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: .25rem;
      height: 75px;
      width: 90px;
      img {
        max-height: calc(75px - .25rem * 2);
      }
    }
  }
}

.talk {
  background-color: $light;
  padding: 2.125rem 1.5rem 1.875rem 1.875rem;
  height: 100%;
  &-label {
    text-transform: uppercase;
    background-color: $danger;
    color: $white;
    padding: .5rem 1.5rem;
    display: inline-block;
    font-size: .75rem;
    font-weight: $font-weight-bold;
    line-height: 1;
  }
}

.bread-brd {
  border: 1px solid transparent;
  border-top-color: $light;
  border-bottom-color: $light;
  @include media-breakpoint-up(md){
    border: none;
  }
}

.list-p {
  padding-left: 1.125rem;
}

.list-disc {
  list-style: disc;
}

.info-slider {
  .slider-dots {
    margin-top: .5rem;
  }
}

.vertical-line-white {
  width: 1px;
  height: 100%;
  background-color: $white;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  margin-bottom: 0;
}

.slick-detailed-prev {
  left: calc(var(--bs-gutter-x) * .5rem);
  transform: translate(calc(var(--bs-gutter-x)), -50%);
}

.slick-detailed-next {
  transform: translate(calc(var(--bs-gutter-x) * -.5), -50%) rotate(180deg);
}

.breadcrumb-live {
  min-width: 250px;
  background-color: rgba(255, 255, 255, 0.6);
  padding: .5rem 1rem;

  .active {
    color: $danger;
  }
}

.navlink-live {
  padding-top: 1.125rem;
  padding-bottom: 1.125rem;
}

.navlink-live-active {
  border-bottom: 3px solid $danger
}

.navlink-live-disabled {
  pointer-events: none;
  cursor: default;
  text-decoration: none;
  color: black;
}

.border-start-md {
  border-left: none;

  @media only screen and (min-width: 992px) {
    border-left: 1px solid #b7c3ca;
  }
}

.rounded-bg {
  font-size: .9rem;
  padding: .1rem 15px;
  color: white;
  position: relative;
  transform: translateX(-10px);
  min-width: 10rem;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    transform: translateX(10px);
    width: 20px;
    height: 100%;
    border-radius: 50%;
  }

  &-end {
    background-color: #318d1f;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
    width: 15px;
  }

  &-green {
    background-color: #318d1f;
    opacity: 0.7;

    &:after {
      background-color: #318d1f;
    }

    &-end {
      opacity: 0.7;
      background-color: #318d1f;
    }
  }

}

.voting-icon {
  position: absolute;
  top: 0;
  right: 6%;
  transform: translateY(-50%)
}

.modal-success-indicator {
  svg {
    width: 100px;
    display: block;
    margin: 40px auto 0;
  }

  .path {
    stroke-dasharray: 1000;
    stroke-dashoffset: 0;
    &.circle {
      -webkit-animation: dash .9s ease-in-out;
      animation: dash .9s ease-in-out;
    }
    &.line {
      stroke-dashoffset: 1000;
      -webkit-animation: dash .9s .35s ease-in-out forwards;
      animation: dash .9s .35s ease-in-out forwards;
    }
    &.check {
      stroke-dashoffset: -100;
      -webkit-animation: dash-check .9s .35s ease-in-out forwards;
      animation: dash-check .9s .35s ease-in-out forwards;
    }
  }

  p {
    text-align: center;
    margin: 20px 0 60px;
    font-size: 1.25em;
    &.success {
      color: #73AF55;
    }
    &.error {
      color: #D06079;
    }
  }


  @-webkit-keyframes dash {
    0% {
      stroke-dashoffset: 1000;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }

  @keyframes dash {
    0% {
      stroke-dashoffset: 1000;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }

  @-webkit-keyframes dash-check {
    0% {
      stroke-dashoffset: -100;
    }
    100% {
      stroke-dashoffset: 900;
    }
  }

  @keyframes dash-check {
    0% {
      stroke-dashoffset: -100;
    }
    100% {
      stroke-dashoffset: 900;
    }
  }

}

.voting-popup-banner {
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
  min-height: 300px;
  width: 95%;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;


  &-main-img {
    flex-grow: 1;
    height: 100%;
    object-fit: cover;
    clip-path: polygon(0% 0%, 68% 0, 100% 0, 80% 100%, 0% 100%);

    @media (max-width: 1300px) {
      width: 75%;
    }
  }

  &-content-container {
    display: flex;
    flex-grow: 3;
    justify-content: space-between;
    padding: 20px
  }

  @media (max-width: 1300px) {
    width: 75%;
    flex-wrap: wrap;

    &-email-input-container {
      width: 100%;

      input {
        width: 100% !important;
      }
    }

    &-register-btn {
      width: 100%;
    }
  }

  @media (max-width:675px) {
    width: 100%;
    left: 0%;
    top: 0%;
    transform: translate(0%, 0%);
    flex-wrap: wrap;

    &-email-input-container {
      width: 100%;

      input {
        width: 100% !important;
      }
    }

    &-register-btn {
      width: 100%;
    }
  }

}

.comparison-table {
  width: 100%;

  th {
    font-size: 1.1rem;
  }

  &-column-td {
    padding: 8px;
    text-align: center;
  }

  td {
    border-bottom: 1px solid  #979797;
  }

  &-data-item {
    max-width: 400px;
    font-size: 1.1rem;
    font-weight: 600;
  }

  @media (max-width:675px) {
    th {
      font-size: .9rem;
    }

    td {
      font-size: 0.9rem;

      img {
        width: 25px;
        height: 25px;
      }
    }
  }
}

.icon-button-container {
  min-height: 60px;
  padding: 12px;
  background: #F8F8F8;
  display: flex;
  justify-content: center;
  align-items: center;
}

.orangeBtn {
  background-color: #F47629;
  border: none;
}

.orangeBtnDisabled {
  background-color: #e8b897
}

.greenButtonDisabled {
  background-color: #a4daa4;
}

.dashed-hr {
  border-top:1px dashed #000;
  background-color: transparent
}

.qr-camera-preview-container {
  min-width: 250px;
  min-height: 250px;
  background: black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay-img {
  height: 50vh;

  @media screen and (max-width: 700px) {
    height: 70vh;
  }
}

.overlay-header-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  padding: 1rem;

  h1 {
    font-size: 3rem;
    font-weight: 500;
    text-align: center;
    margin-bottom: 0;
    color: $white;
  }

  .subHeader {
    margin-top: 1rem;
    font-size: 1.8rem;
    font-weight: 300;
    text-align: center;
    margin-bottom: 0;
    color: $white;
  }

  @media screen and (max-width: 700px) {
    width: 100%;
    padding: 0.5rem;

    h1 {

      font-size: 2rem;
    }

    .subHeader {
      font-size: 1rem;
    }
  }
}


.weblink-container {
 background: #F4F1F1;
  color: black;
}

.column-2-article {
  column-count: 2;

  @media screen and (max-width: 700px) {
    column-count: 1;
  }
}

.invisible-t {
  visibility: hidden;
}
