

.klaro {
  --notice-max-width: 600px;
  --border-radius: 0px;
  --dark2: $seconadry;


  .cm-btn,
  button {
    @extend .btn;
    @extend .btn-sm; }

  .cn-ok {
    .cm-link {
      @extend .btn;
      @extend .btn-sm;
      @extend .btn-outline-primary; } }

  .cm-btn-danger {
    @extend .btn-primary; }

  .cm-btn-success {
    @extend .btn-danger; }

  .cn-ok {
    padding-top: .5rem;
    align-items: flex-end !important;
    justify-content: space-between !important; }

  h1.title {
    @extend .h3;
    margin-bottom: 1.5rem; } }


.cm-btn,
button {

  & + .cm-btn {
    margin-left: .5rem; } }
