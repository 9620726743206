body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  // font-size: .9375rem;
}
.content {
  height: 100%;
  @include media-breakpoint-up(lg) {
    padding-right: calc(var(--bs-gutter-x) * 1);
    // border-right: $border-width * 2 solid $border-color;
  }
}
.page-cat {
  padding: .375rem .5rem;
  background-color: $light;
}
.page-header {
  @include margin-bottom(3.75rem);
  @include font-size(2rem);
  text-align: center;
  position: relative;
  text-transform: uppercase;
  border: $border-width * 3 solid $black;
  padding: .5rem 3rem;
  &-link {
    position: absolute;
    width: 290px;
    max-width: 90%;
    text-align: center;
    background: $border-color;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    @include font-size(.875rem);
    line-height: 28px;
    padding: 0 1rem;
    color: $white;
    right: 0;
    top: 100%;
    transform: translateY(-50%);
    &:hover {
      color: $white;
    }
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 100%;
      bottom: 0;
      width: 14px;
      border: 14px solid transparent;
      border-left-color: $border-color;
      border-left-width: 10px;
    }
  }
}

.page-subheader {
  border-bottom: $border-width dashed $primary;
  margin-bottom: 1.125rem;
  padding-bottom: .5rem;
  text-transform: uppercase;
  font-weight: $font-weight-bolder;
  @include font-size(1.125rem);
  a {
    font-size: .8125rem;
  }

  //&:before {
  //  color: $danger;
  //  content: "//";
  //  margin-right: 3px;
  //}
}

.dotted-bottom-border {
  border-bottom: $border-width dashed $primary;
}

.page-content {
  @include margin-bottom(100px);
  @include padding-top(3rem);
  p {
    margin-bottom: .9375rem * 1.5;
  }
}

.page-hero {
  @include margin-bottom(100px);
  @include padding-top(3.5rem);
  @include padding-bottom(3.5rem);

  &.bg-primary {
    .page-header {
      color: $white;
      border-bottom-color: rgba($white, .3);
    }
  }

  &-fav {
    background-color: $light;

    @include media-breakpoint-up(md) {
      background: $light url('../img/fav-bg.png') no-repeat right -100px center / auto 100%;
    }
    @include media-breakpoint-up(xl) {
      background-position: 85% center;
    }
  }
}

.list-check {
  @include list-unstyled();

  li {
    display: block;
    margin-bottom: 1.0625rem;
    position: relative;
    padding-left: 2rem;
    background: transparent url('../img/list-check.png') no-repeat left center / 21px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.abs-overlay {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: block;
}

.mm-user {
  display: none !important;
}

.body-user {

  .mm-user {
    display: block !important;
  }

  .mm-nouser {
    display: none !important;
  }

}


[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak {
  display: none !important;
}

.dmas_chat_window {
  *,
  *::before,
  *::after {
    box-sizing: content-box;
  }
}

.page-wrap {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.body-loading {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: $zindex-tooltip + 10;
  background: rgba($white, .95);
}

.ratio {
  > .spinner-border {
    width: 2rem;
    height: 2rem;
    left: calc(50% - 1rem);
    top: calc(50% - 1rem);
  }
}


.dealer {
  &-content{
    padding: 1rem;

    @include media-breakpoint-up(lg){
      padding: 2.875rem 3.275rem 1.5rem 2.875rem;
    }
  }
}

.reg-btn{
  margin-bottom: 75px;
  @include media-breakpoint-up(md){
    transform: translateY(-10rem);
    margin-bottom: -4rem;
  }
}

.btn-outline-border{
  color: #000;
}

.abs-img {
  object-fit: contain;
  height: 100%;
  width: 100%;
}

.article-dealer {
  background-color: $light;
  height: 100%;
  padding: 1.5rem;

  &-box {
    display: block;
    text-decoration: none;
    position: relative;
    border: 2px solid #fff;
    transition: $transition-base;

    .chek{
      display: none;
    }

    &:hover {
      border: 2px solid #c51b1d;
      .article-dealer-content{
        background-color: #cccccc;
      }
    }

    &.active{
      border: 2px solid #c51b1d;
      .article-dealer-content{
        background-color: #cccccc;
      }
      .chek{
        display: block;
      }
    }
  }

  &-img {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $white;
    height: 100%;
    padding: .5rem;
  }

  &-content {
    padding:  .75rem;
    flex: 1 1 auto;
    color: #000;
    background-color: transparent;
  }

}


.modal-content{

  .btn-close{
    position:absolute;
    top: 1rem;
    right: 1rem;
    z-index: 123;
  }

}

.page-divider {
  height: 1px;
  width: 100%;
  border-top: $border-width dashed $primary;
  margin-bottom: 1rem;
}

.img-fit {
  width: 100%;
  height: 100%;
  display: block;
}

.img-fit-cover {
  object-fit: cover;
}

.img-fit-contain {
  object-fit: contain;
}

.subheader-title {
  text-transform: uppercase;
  font-weight: $font-weight-bolder;
  @include font-size(1.125rem);

  &:before {
    color: $danger;
    content: "//";
    margin-right: 3px;
  }
}

.dotted-border-hr {
  border-bottom: $border-width dashed $primary;
  margin-bottom: 1.125rem;
  padding-bottom: .5rem;
}

.font-size {
  &-07 {
    font-size: 0.7rem;
  }

  &-08 {
    font-size: 0.8rem;
  }

  &-09 {
    font-size: 0.9rem;
  }

  &-10 {
    font-size: 1rem;
  }

  &-11 {
    font-size: 1.1rem;
  }

  &-12 {
    font-size: 1.2rem;
  }

  &-13 {
    font-size: 1.3rem;
  }

  &-14 {
    font-size: 1.4rem;
  }

  &-15 {
    font-size: 1.5rem;
  }

  &-30 {
    font-size: 3.0rem;
  }
}
