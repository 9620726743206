.header {
  transition: $transition-base;
  border-bottom: $border-width solid $light;
  // box-shadow: 0 .3125rem 1.125rem rgba($black, .15);

  &__ph {
    height: 54px;
  }

  &__top {
    display: none;
    //border-bottom: $border-width dashed $border-color;
    height: 50px;
  }

  &__bottom {
    padding: 0;
  }

  .logo-img {
    height: 34px;
  }


  @include media-breakpoint-up(md) {
    &__top {
      display: flex;
    }
    &__bottom {
      padding: .5rem 0;
    }
    &__ph {
      height: 120px;
    }
    &-user {
      transform: translateY(0);
    }
    &--not-top {
      &.header-user {
        transform: translateY(-50px);
      }

      &.header-banner {
        transform: translateY(-150px);
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .logo-img {
      height: auto;
    }
    &__ph {
      height: 145px;
    }
    &__bottom {
      padding: .625rem 0;
    }
    &--not-top {
      .header__bottom {
        padding: .5rem 0;
      }
      .logo-img {
        height: 34px;
      }
    }
  }

  @include media-breakpoint-up(xl) {
    &__ph {
      height: 153px;
    }
  }

}


.header-link {
  @include font-size(.8125rem);
  font-weight: $font-weight-semibold;
  display: flex;
  align-items: center;
  height: 50px;
  //border-bottom: 3px solid transparent;
  transition: $transition-base;
  .fi-20 {
    transform: translateY(-1px);
  }

  &-user {
    color: $body-color;

    .fi {
      stroke: $gray-800;
    }

  }

  &.active {
    border-bottom-color: $danger;
  }

}


.tasche {
  display: block;
  position: relative;
  margin-left: 2.5rem;
  transform: translateY(-3px);
  span {
    color: white;
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
    transform: translate(25%, 25%);
    border-radius: 50%;
    background: $danger;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    font-size: .625rem;
  }
}
