.fi {
  width: 24px;
  height: 24px;
  stroke: currentColor;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
  transition: stroke 0.3s ease-in-out;
}
.fi-8 {
  width: 8px;
  height: 8px;
}
.fi-12 {
  width: 12px;
  height: 12px;
} 
.fi-16 {
  width: 16px;
  height: 16px;
}
.fi-20 {
  width: 20px;
  height: 20px;
}
.fi-24 {
  width: 24px;
  height: 24px;
}
.fi-26 {
  width: 26px;
  height: 26px;
}
.fi-28 {
  width: 28px;
  height: 28px;
}
.fi-32 {
  width: 32px;
  height: 32px;
}
.fi-36 {
  width: 36px;
  height: 36px;
}
.fi-40 {
  width: 40px;
  height: 40px;
}
.fi-45 {
  width: 45px;
  height: 45px;
}
.fi-60 {
  width: 60px;
  height: 60px;
}
.fi-120 {
  width: 120px;
  height: 120px;
}


.ic {
  display: block;
  position: relative;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  text-decoration: none;
  z-index: 1;
  transition: $transition-base;
  box-shadow: none;
  background: $light no-repeat center / 20px;

  &-w {
    background-color: $white;
  }

  &[href]:not(.no-hover) {
    &:hover {
      background-color: rgba($primary, .25)
    }
  }

  &-sm {
    width: 28px;
    height: 28px;
    background-size: 16px;
  }
  &-lg {
    width: 40px;
    height: 40px;
    background-size: 24px;
  }
  &-xl {
    width: 60px;
    height: 60px;
  }

  .spinner-border {
    display: none;
  }

  &.ratio {
    .spinner-border {
      display: block;
    }
  }
}

.ic-add {
  &:before {
    content: "";
    display: block;
    position: absolute;
    bottom: 6px;
    right: 6px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: $border-color no-repeat center / 80%; 
  }
  
  &.ic-active {
    &:before {
      background-color: $danger;
    }
  }

  &.ic-bag-w {
    &:before {
      bottom: 4px;
      right: 4px;
    }
    
  }

  &-next {
    &:before {
      background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='#{$black}' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'><polyline points='9 18 15 12 9 6'></polyline></svg>"));
    }
  }

  &-plus {
    &:before {
      background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='#{$black}' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'><line x1='12' y1='5' x2='12' y2='19'></line><line x1='5' y1='12' x2='19' y2='12'></line></svg>"));
    }
    &.ic-active {
      &:before {
        background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='#{$white}' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'><polyline points='20 6 9 17 4 12'></polyline></svg>"));
      }
    }
  }
}

.ic-like {
  background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='#{$black}' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'><path d='M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z'></path></svg>"));

  &.ic-active {
    background-color: $danger;
    background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='#{$white}' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'><path d='M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z'></path></svg>"));
  }
}

.ic-list {
  background-image: url('../img/icon-list@2x.png');
  background-size: 15px 18px;
}
.ic-more {
  background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='#{$black}' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'><circle cx='12' cy='12' r='1'></circle><circle cx='19' cy='12' r='1'></circle><circle cx='5' cy='12' r='1'></circle></svg>"));  
    background-size: 18px;
}

.ic-man {
  background-image: url('../img/poster/man_icon.png');
  // background-size: 15px 18px;
  border-radius: 50%;
}

.ic-exh {
  background-image: url('../img/icon-exh@2x.png');
  background-size: 19px 21px;
}

.ic-exh-arrow {
  background-image: url('../img/icons/exh-arrow.svg');
  background-size: 19px 21px;
}

.ic-exh-check {
  background-image: url('../img/icons/exh-check.svg');
  background-size: 19px 21px;
}

.ic-view {
  background: transparent escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='#{$body-color}' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'><path d='M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z'></path><circle cx='12' cy='12' r='3'></circle></svg>")) no-repeat center / 20px
}

.ic-share {
  background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='#{$body-color}' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'><circle cx='18' cy='5' r='3'></circle><circle cx='6' cy='12' r='3'></circle><circle cx='18' cy='19' r='3'></circle><line x1='8.59' y1='13.51' x2='15.42' y2='17.49'></line><line x1='15.41' y1='6.51' x2='8.59' y2='10.49'></line></svg>"));
  background-position: left 45% center;
}

.ic-down {
  background-color: transparent;
  background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='#{$body-color}' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'><path d='M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4'></path><polyline points='7 10 12 15 17 10'></polyline><line x1='12' y1='15' x2='12' y2='3'></line></svg>"));
  background-size: 22px;
}

.ic-close {
  background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='#{$body-color}' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'><line x1='18' y1='6' x2='6' y2='18'></line><line x1='6' y1='6' x2='18' y2='18'></line></svg>"));  
}

.ic-alert {
  background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='#{$body-color}' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'><circle cx='12' cy='12' r='10'></circle><line x1='12' y1='8' x2='12' y2='12'></line><line x1='12' y1='16' x2='12.01' y2='16'></line></svg>"));   
}

.ic-share {
  background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='#{$body-color}' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'><circle cx='18' cy='5' r='3'></circle><circle cx='6' cy='12' r='3'></circle><circle cx='18' cy='19' r='3'></circle><line x1='8.59' y1='13.51' x2='15.42' y2='17.49'></line><line x1='15.41' y1='6.51' x2='8.59' y2='10.49'></line></svg>"));   
}

.ic-down2 {
  background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='#{$body-color}' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'><path d='M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4'></path><polyline points='7 10 12 15 17 10'></polyline><line x1='12' y1='15' x2='12' y2='3'></line></svg>"));
}

.ic-label {
  @include font-size(.6875rem);
  display: flex;
  align-items: center;
  color: $danger;
  font-weight: $font-weight-semibold;
  white-space: nowrap;
  @include media-breakpoint-up(md){
    @include font-size(.8125rem);
  }

  &[href] {
    &:hover {
      span.ic {
        background-color: rgba($primary, .25);
      }
    }
  }

}

button.ic-label {
  &:hover {
    span.ic {
      background-color: rgba($primary, .25);
    }
  }
}

// .copy-link{
//   &:hover{
//     svg {
//       opacity: .8;
//     }
//   }
// }

.ic-ask {
  background-image: url('../img/icons/ask.png');
  background-size: 22px;
  opacity: .3;
  &.ic-active {
    opacity: 1;
  }
}

.ic-katalog {
  background-image: url('../img/icons/katalog.png');
  background-size: 22px;
  opacity: .3;
  &.ic-active {
    opacity: 1;
  }
}

.ic-exh2 {
  background-image: url('../img/icon-exh@2x.png');
  background-size: 19px 21px;
  opacity: .3;
  &.ic-active {
    opacity: 1;
  }
}

