.rect {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;

  &-body {
    flex: 1 1 auto;
    padding: 1.75rem 1.5rem 1rem;
  }

}

.img-ratio {
  object-fit: cover;
  width: 100%;
  height: 100%;
}