.bg-img {
  position: relative;
  overflow: hidden;
}

.bg-img-topics {
  background-image: url("../img/bg.jpg");
  background-repeat: no-repeat;
  background-position: top center;
  background-size: contain;
}

.bg-img-bg2 {
  background-image: url("../img/bg2.jpg");
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
}

.bg-img-bg3 {
  background-image: url("../img/bg-3.jpg");
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
}

.bg-img-bg4 {
  background-image: url("../img/bg-4.jpg");
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
}

.bg-img-bg5 {
  display: none;
  background-image: url("../img/bg-5.jpg");
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
}

.abs-bg {
  z-index: 100;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.deals-info{
  background-color: #40a097;
  margin-bottom: 75px;
  @include media-breakpoint-up(md){
    transform: translateY(-8rem);
    margin-bottom: -4rem;
  }

  &-bg1{
    color: $white;
    padding: 3rem 2rem 2rem;
    text-align: center;
  }

  &-bg2{
    background-color: rgba($white, .5)
  }
}

.content-bg2{
  padding: 1rem;
  @include media-breakpoint-up(lg){
    padding: 2.125rem;
    padding-right: 6.5rem;
  }
}

#traderModal {
  .col-lg-6 {
    > .scroll-traders {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    .scroll-content {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
  }

  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
  }

  @include media-breakpoint-up(lg){
    padding-bottom: 100px;
    .modal-fullscreen-lg-down .modal-content {
      height: 100%;
    }
  }
}

.bg-traders {
  background-color: rgba($gray-100, .5);  
}

.scroll-traders {
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 350px;
  overflow-y: auto;
  // min-height: 300px;
  @include media-breakpoint-up(lg) {
    max-height: 40vh;
    min-height: 30vh;
  }

  & > .scroll-element.scroll-y {
    width: 6px;
    right: 5px;
  }

  .scroll-element.scroll-x {
    display: none;
  }
  .scroll-element_track {
    background-color: transparent !important;
  }
  .scroll-bar {
    background-color: $gray-500 !important;
  }

  &-mobile {
    max-height: 100%;

    @include media-breakpoint-up(lg){
      overflow: visible !important;
      max-height: none;
    }
  
    & > .scroll-element.scroll-y {
      width: 6px;
      right: 5px;
    }
  
    .scroll-element.scroll-x {
      display: none;
    }
    .scroll-element_track {
      background-color: transparent !important;
    }
    .scroll-bar {
      background-color: $gray-500 !important;
    }
  }
}
