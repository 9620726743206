

.tile {
  position: relative;
  // box-shadow: 0 0 1.25rem rgba($black, .2);
  @include font-size(.9375rem);
  border: 2px solid $white;

  &__inner {
    overflow: hidden;
    background: $white;
    padding: 1.125rem 1.25rem;

    @include media-breakpoint-up(lg) {
      padding: 1.5rem 1.625rem;
    }

    > *{
      margin-bottom: 1.25rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &-adv {
    border-color: $danger;
    &:before {
      content: "WERBUNG";
      color: $white;
      font-weight: $font-weight-bold;
      @include font-size(.75rem);
      background: $danger;
      position: absolute;
      top: 0;
      right: 0;
      padding: 0 0.5rem;
      line-height: 1.625rem;
    }
  }

  &-summer {
    border: 0;
    text-align: center;
    .tile__inner {
      display: flex;
      height: 380px;
      flex-direction: column;
      justify-content: space-between;
      background: url('../img/summertime.jpg') no-repeat center / cover;
    }
    &-title {
      color: $white;
      @include font-size(1.875rem);
      text-shadow: 0 0 0.625rem $primary;
    }
  }

  &-primary {
    border: 0;
    .tile__inner {
      background: $primary;
    }

    .tile-header {
      @include font-size(1.0625rem);
      color: $white;
      border-bottom-color: $white;
    }
  }

  img:not(.fav-exh-logo) {
    max-height: 560px !important;
  }

  img:is(.max-width-img) {
    max-height: 100% !important;
    width: 100%;
  }
}

.bg-light {
  .tile {
    border-width: 0;
  }
}

.tile-title {
  @include font-size(1.1875rem);
  font-weight: $font-weight-bold;
  line-height: 1;
  font-family: $font-family-sans-serif;
}

.tile-divider {
  border-top: $border-width dashed $primary;
}

.tile-avatar {
  display: block;
  overflow: hidden;
  height: 45px;
  width: 45px;
  border-radius: 50%;
  box-shadow: $box-shadow-sm;
  img {
    object-fit: contain;
  }
}
.tile-footer {
  @include font-size(.8125rem);
  color: $info;
  line-height: $line-height-sm;
  font-weight: $font-weight-semibold;
  position: relative;
  padding-right: 2rem;
  &-divider {
    margin-top: 4px;
    margin-bottom: 4px;
    align-self: stretch;
    display: flex;
    align-items: center;
    border-right: $border-width dashed $primary;
  }
  .fw-b {
    @include font-size(.875rem);
  }
}

.tile-more {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;

  .dropdown-menu {
    &:before {
      content: "";
      position: absolute;
      right: 1.25rem;
      bottom: 100%;
      border: 10px solid transparent;
      border-bottom-color: $dropdown-bg;
    }
  }
}

.tile-nav {
  .dropdown{
    background: transparent escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='#{$dark}' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'><polyline points='6 9 12 15 18 9'></polyline></svg>")) no-repeat right  center / 1.25rem;
    .dropdown-menu {
      display: block;
      opacity: 0;
      visibility: hidden;
      right: -1.25rem;
      top: 2rem;
      transition-property: opacity, visibility;
      transition-duration: 0.3s;
      transition-delay: 0.15s;
    }

    &:hover {
      .dropdown-menu {
        display: block;
        right: -1.25rem;
        top: 2rem;
        opacity: 1;
        visibility: visible;
      }
    }
  }
  .dropdown-menu {
    &:before {
      content: "";
      position: absolute;
      right: 1.25rem;
      bottom: 100%;
      border: 10px solid transparent;
      border-bottom-color: $dropdown-bg;
    }
  }
}



.tile-thumbs {
  display: flex;
  border: $border-width * 2 solid $light;
  justify-content: center;
  &-item {
    flex: 0 0 20%;
    max-width: 20%;
    border-left: $border-width * 2 solid $light;
    text-align: center;
    position: relative;

    &:first-child {
      border-left: 0
    }
    img {
      max-height: 100%;
      max-width: 100%;
      left: auto;
      top: auto;
    }
  }
}

.img-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.tile-media {
  &-item {
    position: relative;
    
  }
}
.tile-slider {
  position: relative;
  &:before {
    content: "";
    display: block;
    top: 0;
    bottom: 0;
    right: 100%;
    width: 2rem;
    background: $white;
    position: absolute;
    z-index: 1;
  }
}

.tile-slide {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-size: contain;
  img {
    max-width: 100%
  }

  &-body {
    flex: 1 1 auto;
    padding: .875rem;
    border: $border-width * 2 solid $light;
  }
  &-title {
    font-weight: $font-weight-bold;
    @include font-size($font-size-base);
    margin: 0;
  }
}

.tile-header {
  text-transform: uppercase;
  padding-bottom: 1rem;
  line-height: $line-height-sm;
  margin-bottom: 1rem;
  border-bottom: $border-width dashed $primary;
}

.tile-list-item {
  @include font-size(.875rem);
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: $border-width dashed $primary;
  p {
    margin-bottom: .5rem;
  }
  &:last-child {
    border: 0;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}
.tile-slide-img {
  // height: 400px;
  img {
    max-height: 400px;
    // max-width: 400px;
    height: 100%;
  }
}

.post-slider-img {
  object-fit: contain;
  width: 560px;

  @media (max-width: 991px){
    width: 650px;
    object-fit: fill;
  }

  @media (max-width: 768px){
    width: 100%;
    object-fit: cover;
  }


}
