

.avatar {
  display: flex;
  align-items: center;
  @include font-size(.8125rem);
  color: $info;
  line-height: $line-height-sm;
  font-weight: $font-weight-semibold;

  .fw-b {
    @include font-size(.875rem);
  }
  .tile-avatar {
    img {
      height: 100%;
      width: 100%;
    }
  }
  &-square,
  .abox__thumb {
    width: 50px;
    height: 50px;
    flex: 0 0 50px;
    border: $border-width solid $border-color;
    padding: .125rem;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-height: 100%;
      max-width: 100%;
    }
  }

  &-voting {
    padding: 5px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
}