.form-floating {

  > label {
    font-size: .75rem;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
  }

  // stylelint-disable no-duplicate-selectors
  > .form-control {


    //padding-top: $form-floating-input-padding-t;
    //padding-bottom: $form-floating-input-padding-b;
    
  }

  .form-control,
  .form-select {
    color: $danger;
    border-width: $border-width;
    border-color: $input-bg;
  }
}

.form-download {
  .form-place {
    z-index: 0;
  }
  .form-label {
    pointer-events: auto;
    width: 100%;
    margin: 0;
  }
}

.form-control-white {
  background-color: $white;
  //border-color: $white;
 
  &:focus {
    background-color: $white;
    //border-color: $white;
  }
  
  &:disabled,
  &[readonly] {
    background-color: $white;
    border-color: $white;   
  }

}
.form-control-plus {
  background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='#{$body-color}' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'><line x1='12' y1='5' x2='12' y2='19'></line><line x1='5' y1='12' x2='19' y2='12'></line></svg>"));
  background-position: right .75rem center;
  background-size: 24px;
  background-repeat: no-repeat;
}

.form-check {
  font-size: $font-size-base;
}
.form-check-label {
  font-size: $font-size-base * .8125;
  line-height: $font-size-base;
}
.form-check-input-white {
  background-color: $white;
}

.form {
  .placeholder {
    height: $form-floating-height;
  }
  .invalid-feedback {
    position: absolute;
    left: 0;
    top: calc(100% + 0px);
    //font-style: italic;
  }
  .form-select {
    &.ng-invalid.ng-not-empty {
      @extend .form-select.is-invalid;
      & ~ .invalid-feedback {
        display: block;
      }
    }
    &.ng-valid.ng-not-empty.ng-touched {
      @extend .form-select.is-valid;
    }
  }
  .form-control:not(.form-code) {
    &.ng-invalid.ng-not-empty {
      @extend .form-control.is-invalid;
      & ~ .invalid-feedback {
        display: block;
      }
    }
    &.ng-valid.ng-not-empty.ng-touched {
      @extend .form-control.is-valid;
    }
  }
  .form-control.form-code-valid {
    &.ng-valid.ng-not-empty.ng-touched {
      @extend .form-control.is-valid;
    }
  }
  .form-check-input {
    &.ng-invalid.ng-touched {
      @extend .form-check-input.is-invalid;
      & ~ .invalid-feedback {
        display: block;
      }
    }
    &.ng-valid.ng-not-empty.ng-touched {
      @extend .form-check-input.is-valid;
    }
  }
}


.form-switch-right {
  padding-right: $form-switch-padding-start;
  padding-left: 0;
  display: flex;
  align-items: center;

  .form-check-input {
    margin-right: $form-switch-padding-start * -1;
    margin-left: auto;    
    height: 1.25em;
    margin-top: 0;
    &:checked {
      border-color: $success;
      background-color: $success;
    }
    &:focus {
      box-shadow: none;
    }
  }
}