
.footer {
  @include padding-top(3rem);
  @include padding-bottom(3rem);
  font-size: .9375rem;

  margin-top: auto;
  background: $light;
  
  &__links {
    display: flex;
    font-size: .8125rem;
    color: $gray-600;
    a {
      color: inherit;
    }
    span {
      display: inline-block;
      padding: 0 4px;
    }
  }

  h5 {
    letter-spacing: -.21px;
    margin-bottom: 1.75rem;
  }

}