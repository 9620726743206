/* Slider */
.slick-slider {

  position: relative;

  display: block;
  box-sizing: border-box;

  user-select: none;

  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {

  position: relative;

  display: block;
  overflow: hidden;

  margin: 0;
  padding: 0;
}
.slick-list:focus {

  outline: none;
}
.slick-list.dragging {

  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {

  transform: translate3d(0, 0, 0);
}

.slick-track {

  position: relative;
  top: 0;
  left: 0;
  min-width: 100%;

  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before,
.slick-track:after {

  display: table;

  content: '';
}
.slick-track:after {

  clear: both;
}
.slick-loading .slick-track {

  visibility: hidden;
}

.slick-slide {

  display: none;
  float: left;

  height: 100%;
  min-height: 1px;
}
[dir='rtl'] .slick-slide {

  float: right;
}
.slick-slide img {

  display: block;
}
.slick-slide.slick-loading img {

  display: none;
}
.slick-slide.dragging img {

  pointer-events: none;
}
.slick-initialized .slick-slide {

  display: block;
}
.slick-initialized + .row-load {
  display: none;
}

.slick-initialized.row {
  .slick-slide {
    @include make-col-ready();
    @include media-breakpoint-down(sm){
      padding-left: calc(var(--bs-gutter-x) * .25);
      padding-right: calc(var(--bs-gutter-x) * .25);

    }
  }
}


.tile-slider {
  .slick-slide {
    @include make-col-ready();
    width: auto !important;
  }
}

.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {

  display: block;

  height: auto;

  border: 1px solid transparent;
}
.slick-arrow.slick-hidden  {
  display: none;
}

.slider {
  .slick-track {
    display: flex;
    justify-content: center;
  }
 
}

.slider-g {
  &:before,
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 50vw;
    z-index: 4;
  }
  &:before {
    right: 100%;
    background: linear-gradient(90deg, rgba($white, 1) 0%, rgba($white, 1) calc(100% - 125px), rgba($white, 0.5) calc(100% - 50px), rgba($white, 0) 100%);
  }
  &:after {
    left: 100%;
    background: linear-gradient(270deg, rgba($white, 1) 0%, rgba($white, 1) calc(100% - 125px), rgba($white, 0.5) calc(100% - 50px), rgba($white, 0) 100%);
  }
}

.slider-s {
  position: relative;
  
  .slick-track {
    display: flex;
    align-items: stretch;
  }

  .slick-slide {
    height: auto;
    flex: 0 0 auto;
  }
}

.slider-abox {
  .slick-slide {
    @include make-col-ready();
    //opacity: 0;
    transition: $transition-fade;
  }
  .slick-active,
  .slick-op {
    opacity: 1;
  }
}

.slider-img,
.slider-detail {
  .slider-dots {
    height: 50px;
  }

  .slider-slide {
    display: none;
    &:first-child {
      display: block;
    }
  }

  &.slick-initialized {
    .slider-slide {
      display: block;
    }
  }
}

.slider-img {
  .sc {
    background-color: $gray-100;
  }
}
.slider-img-1{
  .slick-list {
    padding: 0 !important;
  }
}
.slider-detail {
  .slick-list {
    box-shadow: $box-shadow-sm;
  }
  .slider-control {
    transform: translate(-50%, calc(-50% - 25px));
    &-next {
      transform: translate(50%, calc(-50% - 25px)) rotate(180deg);   
    }
  }
  .abs-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.slider-img {
  .slider-control {
    transform: translate(25%, calc(-50% - 25px));
    &-next {
      transform: translate(-25%, calc(-50% - 25px)) rotate(180deg);   
    }
  }
}
.slider-visible {
  .slick-list {
    overflow: visible;
  }
}
.slider-dots {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.slider-dots-item {
  display: block;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: $gray-500;
  margin: 3px;
  cursor: pointer;
  transition: $transition-base;
  &:hover {
    background: $text-muted;
  }
}

.slick-active {
  .slider-dots-item {
    background: $body-color;
  }
}

.slider-control {
  position: absolute;
  display: block;
  top: 50%;
  width: 2.625rem;
  height: 2.625rem;
  
  border-radius: 50%;
  z-index: 5;
  cursor: pointer;
  background: $white escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='#0f374f' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'><polyline points='15 18 9 12 15 6'></polyline></svg>")) no-repeat left calc(50% - 2px) center / 2rem;
  box-shadow: 0 0 0.625rem rgba($black, .2);

  transition: $transition-base;
  transform: translate(-50%, -50%);
  &:hover {
    background-color: $light;
  }
  
  &-prev {
    left: calc(var(--bs-gutter-x));
  }

  &-next {
    right: calc(var(--bs-gutter-x));
    transform: translate(50%, -50%) rotate(180deg);    
  }

  @include media-breakpoint-up(sm) {
    &-prev {
      left: calc(var(--bs-gutter-x) * .5);
    }

    &-next {
      right: calc(var(--bs-gutter-x) * .5);
    }
  }

}

.slickArticl {

  .slider-dots{
    @include margin-top(2rem);
  }

  &-content{
    padding: 4.5rem 4.5rem 2.25rem;
  }

  &-text {
    @include font-size(1.5rem);
    font-style: italic;
  }
}

.slider-stretched {

  .slick-track,
  .slick-list {
    height: 100%
  }

  .slick-track {
    display: flex;
    align-items: stretch;
    justify-content: center;
  }

  .slick-slide {
    height: auto;
    flex: 0 0 auto;
  }
}


.slick-post {
  height: 550px;
  overflow: hidden;
  .tile {
    img {
      max-height: 400px !important;
    }
  }
}

.bg-light,
.slick-post {
  .slider-g {
    &:before {
      background: linear-gradient(90deg, rgba($light, 1) 0%, rgba($light, 1) calc(100% - 125px), rgba($light, 0.5) calc(100% - 50px), rgba($light, 0) 100%);
    }
    &:after {
      background: linear-gradient(270deg, rgba($light, 1) 0%, rgba($light, 1) calc(100% - 125px), rgba($light, 0.5) calc(100% - 50px), rgba($light, 0) 100%);
    }
  }
}


.info-slider {
  .slider-slide {
    display: none;
    &:first-child {
      display: block;
    }
  }

  &.slick-initialized {
    .slider-slide {
      display: block;
    }
  }
}
