

.home {
  @include padding-top(3.75rem);
  @include margin-bottom(3.75rem);
  position: relative;
  &:before {
    content: "";
    position: absolute;
    display: block;
    left: 0;
    right: 0;
    bottom: 100%;
    height: 200px;
    background-color: inherit;
  }
  &__inner {
    overflow: hidden;
  }

  .page-header {
    @include margin-bottom(3.75rem);
  }
}

.home-headline {
  @include font-size($h2-font-size);
  line-height: $line-height-sm;
  @include media-breakpoint-up(md) {
    @include font-size($h1-font-size);
  }
}

.home-img {
  max-width: 75%;
  display: block;
  margin: 0 auto;

  @include media-breakpoint-up(lg) {
    max-width: 150%;

  }

  @include media-breakpoint-up(xl) {
    max-width: 300%;
  }
}

.test-x1 {
  background: blue;
  padding: 20px;
}

@media (min-width: 768px) {

  .carousel-inner .carousel-item-end.active,
  .carousel-inner .carousel-item-next {
    transform: translateX(25%);
  }

  .carousel-inner .carousel-item-start.active,
  .carousel-inner .carousel-item-prev {
    transform: translateX(-25%);
  }
}

.carousel-inner .carousel-item-end,
.carousel-inner .carousel-item-start {
  transform: translateX(0);
}