.navbar-nav {
  .nav-link {
    letter-spacing: 0.9px;
    border-top: 3px solid transparent;
    border-bottom: 3px solid transparent;


  }
  .nav-item {
    margin-left: 1.5rem;

    &:first-child {
      margin-left: 0;
    }
  }


  @include media-breakpoint-up(xl) {
    .nav-link {
      @include font-size(1.125rem);
    }
    .nav-item {
      margin-left: 1.75rem;
    }
  }
}


.navbar-toggler {
  border-width: 0;
}

.navbar-light {
  min-height: 30px;
 
  .navbar-nav {   

    .show > .nav-link,
    .nav-link.active {
      border-bottom-color: $danger;
    }
  }

}

.navbar-trader {
  background: $light;
  @include font-size(.9375rem);
  color: $gray-800;
  padding: .5rem 1rem;
  max-width: 200px;
  line-height: $line-height-sm;
}