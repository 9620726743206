.auth-head {
  position: relative;
  @include margin-bottom(3.75rem);
  &-lock {
    padding-right: 55px;
    background: transparent url('../img/lock@2x.png') no-repeat right 1.5rem center / 30px;
  }
  @include media-breakpoint-up(lg) {
    &:not(.auth-head-auto) {
      min-height: $h3-font-size * $line-height-sm * 2;
    }
     &-lock {
      background-position: right center;
      background-size: 49px;
    }
  }
}

.auth-type {
  display: block;
  border: 3px solid $light;
  transition: $transition-base;
  cursor: pointer;
  padding: 1.875rem;
  line-height: $line-height-sm;
  font-size: 13px;
  background: $white;
  &-title {
    @include font-size(1.375rem);
    margin-bottom: 4px;
  }
  &-inner {
    position: relative;
    padding-left: 2.375rem;
  }
  &-ind {
    position: absolute;
    left: 0;
    top: 2px;
    border: 2px solid $black;
    border-radius: 50%;
    background: $white escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='#{$white}' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'><polyline points='20 6 9 17 4 12'></polyline></svg>")) no-repeat center / 20px;
    transition: $transition-base;
    width: 25px;
    height: 25px;
  }
  &:hover {
    .auth-type-ind {
      border-color: $danger;
    }
  }

  &.active {
    border-color: $danger;
    .auth-type-ind {
      border-color: $danger;
      background-color: $danger;
    }
    &:not(.auth-type-simple) {
      .auth-type-title {
        font-weight: $font-weight-bold;
      }
    }
  }

}

.auth-type-simple {
  border: 0;
  padding: 0;
  background-color: transparent;
}

.auth-success {
  display: flex;
  align-items: center;
  background: $success;
  color: $white;
  line-height: $line-height-sm;
  @include font-size(1.125rem);
  padding: 1.5rem 1.75rem;
  transition: $transition-base;

  @include media-breakpoint-up(md) {    
    padding: 2.25rem 2.5rem;
  }

  .fi-45 {
    flex: 0 0 45px;
  }

  &.show {
    transform: translateX(0);
  }

}

.auth-success-page {
  position: relative;
  //transform: translateY(-50%);
  margin-top: -3.125rem;
}

.auth-success-modal {
  position: absolute;
  left: -1*$modal-header-padding-x;
  top: 0;
  width: calc(100% + #{$modal-header-padding-x});
  padding: $modal-header-padding-x;
  transform: translateX(-100%);
  @include media-breakpoint-up(md) {
    left: -1*2rem;
    padding: 2.5rem 2rem;
    width: calc(100% + 2rem);
  }
}