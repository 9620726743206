.play-btn-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.play-btn {
  border: 5px solid $white;
  border-radius: 50%;
  display: block;
  width: 82px;
  height: 82px;
  background: rgba($black, .49) escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='#{$white}' stroke='#{$white}' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'><polygon points='5 3 19 12 5 21 5 3'></polygon></svg>")) no-repeat left 55% center / 36px;
  box-shadow: 0 0 .625rem rgba($black, .5);
  transition: $transition-base;
  &:hover {
    background-color: rgba($black, .8);
  }
}
