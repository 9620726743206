.down {
  &-item {
    position: relative;
    padding: .25rem 80px .25rem 0;
    height: 40px;
    border-top: $border-width * 2 solid $light;
    display: flex;
    align-items: center;
    //font-weight: $font-weight-semibold;
    line-height: $line-height-sm;
    

    &:first-child {
      border-top: 0;
    }
    .ic {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
    .ic-down {
      right: 0 
    }
    .ic-list {
      right: 40px;
    }
  }
}