.featured-item {
  &-container {
    background: #F6F6F6;
    width: 100%;
    min-height: 300px;
    position: relative;

    .abox {
      background: white;
    }
  }

  &-background {
    position: absolute;
    height: 100%;
    width: 35%;
    //background: #790E07;
    clip-path: polygon(0% 0%, 68% 0, 100% 0, 80% 100%, 0% 100%);

    @media (max-width: 1300px) {
      width: 75%;
    }

    @media (max-width:675px) {
      width: 100%;
    }

    &-voting {
      width: 25%;
      min-width: 400px;
      background: url("/assets/img/voting/voting_featured_bg.png");
      background-size: cover;
    }
  }


  &-title {
    z-index: 10;
    color: $mainColor;
    text-transform: uppercase;
    font-weight: bolder;
    font-size: 2rem;
  }

  &-icon-container {
    z-index: 10;
    background: white;
    padding: 10px;
    border-radius: 50%;
  }

  &-voting-icon-container{
    z-index: 10;
    padding: 10px;
    border-radius: 50%;
  }
}
