
.box {
  background-color: $light;
  display: flex;
  height: 100%;
  flex-direction: column;
}

.box-body {
  flex: 1 1 auto;
  padding: 1rem;

  @include media-breakpoint-up(lg) {
    padding: 1.125rem;
  }

  p:last-child {
    margin-bottom: 0;
  }
}

.box-title {
  font-size: 1.125rem;
  font-weight: $font-weight-bold;
  margin-bottom: 1rem;
}

.box-highlight {
  .ratio {
    @include media-breakpoint-up(sm) {
      --bs-aspect-ratio: 66.435986159%;
    }
    > img {
      object-fit: cover;
    }    
  }
}


.side {
  padding: 1.5rem;
  @include font-size(.875rem);
  &-title {
    @include font-size(.9375rem);
    font-weight: $font-weight-bold;
    margin-bottom: .875rem;
  }
  &-logo {
    padding: 1rem 1.25rem;
    border: $border-width solid $gray-200;
    margin-bottom: 1rem;
    background: $white;
    &-2 {
      height: 154px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        max-height: calc(154px - 2rem);
      }
    }
    &-3 {
      padding: .5rem .75rem;
      height: 110px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        max-height: calc(110px - 1rem);
      }      
    }
  }
  a {
    @include font-size(.8125rem);
  }
  &-divider {
    border-top: $border-width dashed $primary;
    margin: .875rem 0;
  }
}